import React from "react";
import { useNavigate } from "react-router-dom";
const ClampingHyd = () => {
  const nagivate = useNavigate();

  const image1Url =
    "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/18.jpeg?alt=media&token=e2952a30-3689-416e-9a3b-f62c93a9892f";

  const image2Url =
    "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/17.png?alt=media&token=2d14945a-a162-4195-ab4e-02a8daf94163";

  const image3Url =
    "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/12.jpeg?alt=media&token=1af42e32-b878-48be-8b39-d2086f7655cd";
  const closePageHandler = () => {
    nagivate("/product");
  };

  return (
    <div className="rounded-lg mt-10 bg-slate-400 m-12 mx-5">
      <div
        className="flex justify-end text-3xl mx-8 cursor-pointer"
        onClick={closePageHandler}
      >
        <p className="mt-5">X</p>
      </div>

      <p className="flex justify-center text-3xl font-bold mb-4">
        Auto Clamping Fixture Developed For Hard Turning Machine
      </p>

      <div className="container">
        <div className="flex flex-row">
          <div className="flex flex-row ml-5 border-2 border-solid rounded-lg border-blue-600 bg-white p-5">
            <img className="w-[250px] h-[500px]" src={image1Url} alt="" />
            <img
              className="rounded-lg w-[250px] h-[500px]"
              src={image2Url}
              alt=""
            />
          </div>
          <div className="flex flex-row ml-5 border-2 border-solid rounded-lg border-blue-600 bg-white p-5 mr-5">
            <img
              className="rounded-lg w-[550px] h-[350px]"
              src={image3Url}
              alt=""
            />
          </div>
        </div>

        <h2 className="text-3xl font-bold mb-4">Image Description</h2>
        <p className="text-lg leading-relaxed">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
        </p>
      </div>
    </div>
  );
};

export default ClampingHyd;
