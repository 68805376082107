export const productDescription =
  "Supertech Engineering offers Hydraulic Expanding Between centre Gear Grinding Mandrels which can be directly mounted on between centre collet clamping chuck. Also the pressure adjusting screw is provided to increase the clamping pressure of Hydraulic Expanding Gear Grinding Mandrel, if required.";

export const productType = [
  {
    id: 0,
    name: "MANUAL HYD.EXPENDING MANDREL",
    imageLink:
      "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/17.png?alt=media&token=2d14945a-a162-4195-ab4e-02a8daf94163",
  },
  {
    id: 1,
    name: "ROTATIONAL SWITCH TYPE HYD.EXPENDING MANDREL",
    imageLink:
      "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/7.png?alt=media&token=e91c6bb9-f375-4ed0-86aa-a5c6fbb1568a",
  },
  {
    id: 2,
    name: "HYD.CLAMPING FIXTURE FOR GEAR GRINDING M/c AND OD GRINDING M/c",
    imageLink:
      "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/6.png?alt=media&token=d82f01d6-7852-4e85-a4c4-be28b4df571c",
  },
  {
    id: 3,
    name: "HYDRO MACHENICAL CLAMPING FIXTURE FOR GEAR GRINDING M/c",
    imageLink:
      "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/4.png?alt=media&token=68404302-e558-4aa2-a839-7a32a9c9c14c",
  },
  {
    id: 4,
    name: "AUTO CLAMPING FIXTURE FOR HARD TURNING",
    imageLink:
      "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/19.png?alt=media&token=a5fb66bf-2096-48eb-be45-61a074de386b",
  },
];

export const CUSTOMERS1 = [
  "TATA MOTORS LTD.",
  "TOYOTA",
  "JOHN DEERE",
  "HERO MOTOR CROP",
];

export const CUSTOMERS2 = ["MAHINDRA", "FORD", "NISSAN", "HONDA"];

export const HYDRAULIC_FIXTURES_AND_MANDRELS = [
  "Hydraulic Manual Clamping Mandrel",
  "Rotational switch type clamping Mandrel",
  "Hydro-Machanical Fixture",
  "Hydraulic Auto Clampinhg Fixture",
  "Hydraulic OD Grinding Mandrel",
  "Hydraulic Shaping Fixture",
  "Hydraulic Bore and Face Grinding Fixture",
];

export const JIG_AND_FIXTURES = [
  "Face Turning Fixture",
  "VMC Fixture",
  "Milling Fixture",
  "Drilling Fixture",
  "Hobbing Fixture",
  "Shaping Fixture",
];

export const GAUGES = [
  "Plain Ring Gauge",
  "Spline Plug Gauge",
  "Plain Plug Gauge",
  "Width Gauge",
  "Snap Gauge",
  "Taper Mandrel",
  "Solid Height Block",
  "Ring Type Height Block",
  "Taper Plug Gauge",
];
