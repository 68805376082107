import React from "react";
import { useNavigate } from "react-router-dom";

import image1 from "../images/product2.png";

const RotationalHyd = () => {
  const nagivate = useNavigate();

  const closePageHandler = () => {
    nagivate("/product");
  };

  return (
    <div className="rounded-lg mt-10 bg-slate-400 m-12 mx-5">
      <div
        className="flex justify-end text-3xl mx-8 cursor-pointer"
        onClick={closePageHandler}
      >
        <p className="mt-5">X</p>
      </div>

      <p className="flex justify-center text-3xl font-bold mb-4">
        Auto Clamping Fixture Developed For Hard Turning Machine
      </p>

      <div className="container p-6">
        <div className="mx-12 flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between">
          <img
            className="lg:w-1/3 pr-10 rounded-lg w-[60%] h-[60%]"
            src={image1}
            alt=""
          />
          <p className="lg:w-2/3 text-lg leading-relaxed">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
          </p>
        </div>

        <div className="mx-12 mt-8 flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between">
          <p className="lg:w-2/3 text-lg leading-relaxed">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            hendrerit, augue et commodo fermentum, purus elit eleifend lorem.
          </p>

          <img
            className="lg:w-1/3 pr-10 rounded-lg w-[60%] h-[60%]"
            src={image1}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default RotationalHyd;
