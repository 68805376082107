import React, { NavLink } from "react-router-dom";
import downalod from "../images/download-icon.png";

const Header = () => {
  return (
    <nav className="py-5 font-bold bg-white">
      <ul className="flex justify-between font-medium mx-32 text-black">
        <li className="lg:hover:text-blue-800 ">
          <NavLink
            to="/"
            aria-current="page"
            className={({ isActive }) => {
              return isActive ? "text-blue-600" : undefined;
            }}
            end
          >
            HOME
          </NavLink>
        </li>
        <li className="lg:hover:text-blue-800">
          <NavLink
            to="/about"
            className={({ isActive }) => {
              return isActive ? "text-blue-600" : undefined;
            }}
          >
            ABOUT US
          </NavLink>
        </li>
        <li className="lg:hover:text-blue-800 ">
          <NavLink
            to="/product"
            className={({ isActive }) => {
              return isActive ? "text-blue-600" : undefined;
            }}
          >
            PRODUCT
          </NavLink>
        </li>
        <li className="lg:hover:text-blue-800">
          <NavLink
            to="/contact"
            className={({ isActive }) => {
              return isActive ? "text-blue-600" : undefined;
            }}
          >
            CONTACT
          </NavLink>
        </li>

        <li className="w-10 -mt-2 relative group">
          <NavLink
            to="/download"
            className={({ isActive }) => {
              return isActive ? "text-blue-600" : undefined;
            }}
          >
            <img
              className="group-hover:opacity-100"
              src={downalod}
              alt="downalod_image"
            ></img>
            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
              <p className="text-black text-sm mt-12">Download</p>
            </div>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
