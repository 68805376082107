import { createContext, useState } from "react";
import SlideShow from "./SlideShow";
import Home from "./Home";

const IsOpenContext = createContext();

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <IsOpenContext.Provider value={{ isOpen, setIsOpen }}>
      <div className="flex flex-col items-center" id="product">
        <SlideShow />
        <Home />
        {/* <Product /> */}
      </div>
    </IsOpenContext.Provider>
  );
};

export default App;
