import React from "react";
import jigImage from "../images/jig.png";
import JigProductCard from "./JigProductCard";
import image9 from "../images/product9.png";
import image10 from "../images/product10.png";
import image11 from "../images/product11.png";
import image12 from "../images/product12.png";
import image13 from "../images/product13.png";
import image14 from "../images/product14.png";
import image15 from "../images/product15.png";
import image16 from "../images/product16.png";
import image17 from "../images/product17.png";

const data = [
  { title: "PLAIN RING GAUGE", name: "OD Clamping Mandrel", image: image9 },
  { title: "SPLINE RING GAUGE", name: "ID Clamping Mandrel", image: image10 },
  { title: "PLAIN PLUG GAUGE", name: "...", image: image11 },
  { title: "WIDTH GAUGE", name: "OD Clamping Mandrel", image: image12 },
  { title: "SNAP RING GAUGE", name: "ID Clamping Mandrel", image: image13 },
  { title: "TAPER MANDREL", name: "...", image: image14 },
  { title: "SOLID HEIGHT BLOCK", name: "OD Clamping Mandrel", image: image15 },
  { title: "RING HEIGHT BLOCK", name: "ID Clamping Mandrel", image: image16 },
  { title: "TAPER PLUG GAUGE", name: "...", image: image17 },
];

function JigProduct() {
  return (
    <div id="2">
      <div className="flex flex-col items-center border-y-[18px] border-s-8 border-[#0000cb]">
        <h1 className="font-bold text-lg text-[#0000cb]text-center mb-8 mt-5 text-blue-700  ">
          <span className="text-red-700 text-2xl font-bold ">»</span>HYDRAULIC
          FIXTURES/MANDREL- PRINCIPLE OF WORKING
        </h1>
        <p className="font-bold text-sm ml-8">
          Jigs and fixtures are specialised tools used in manufacturing to
          secure workpieces and guide cutting or shaping tools, ensuring
          precision and repeatability in production processes. These specialised
          devices play a pivotal role in securing workpieces, guiding cutting
          tool and ensuring the repeatability of complex operations. They are
          tailored to the specific needs of production, facilitating tasks
          ranging from drilling and welding to milling and assembly. They not
          only enhance the accuracy and quality of finished products but also
          significantly reduce production time, making them invaluable assets in
          modern manufacturing processes.
        </p>
        <img className="w-[450px] mb-5" src={jigImage} alt="jigImage" />
      </div>

      <div className="border-s-8 border-[#0000cb] p-5 space-y-4">
        <JigProductCard data1={data[0]} data2={data[1]} data3={data[2]} />
        <JigProductCard data1={data[3]} data2={data[4]} data3={data[5]} />
        <JigProductCard data1={data[6]} data2={data[7]} data3={data[8]} />
      </div>
    </div>
  );
}

export default JigProduct;
