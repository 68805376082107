import React, { useState } from "react";
import {
  HYDRAULIC_FIXTURES_AND_MANDRELS,
  JIG_AND_FIXTURES,
  GAUGES,
} from "../constant/productType";

import image1 from "../images/product1.png";
import image2 from "../images/product2.png";
import image3 from "../images/product3.png";
import image4 from "../images/product4.png";
import image5 from "../images/product5.png";
import image6 from "../images/product6.png";
import image7 from "../images/product7.png";
import image8 from "../images/product8.png";
import image9 from "../images/product9.png";
import image10 from "../images/product10.png";
import image11 from "../images/product11.png";
import image12 from "../images/product12.png";
import image13 from "../images/product13.png";
import image14 from "../images/product14.png";
import image15 from "../images/product15.png";
import image16 from "../images/product16.png";
import image17 from "../images/product17.png";

const imageProducts = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
];

const imagesJig = [
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
];

const Modal = ({ src, alt, onClose, title }) => {
  return (
    <div
      onClick={onClose}
      className="fixed top-0 left-0 w-full h-full m-5 flex justify-center items-center bg-black bg-opacity-75 z-50"
    >
      <div className="max-w-md bg-white rounded-lg overflow-hidden shadow-xl">
        {/* <button
          onClick={onClose}
          className="absolute m-2 text-black font-bold hover:text-gray-700"
        >
          Close
        </button> */}

        <img src={src} alt={alt} className="w-full" />
        <h1 className="text-2xl text-center text-[#7030a0] my-5 mx-8 font-bold">
          {title}
        </h1>
      </div>
    </div>
  );
};

function OurProduct() {
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [title, setTitle] = useState("");

  const openModal = (url, titleName) => {
    setImageUrl(url);
    setTitle(titleName);
    setShowModal(true);
  };

  const closeModal = () => {
    setImageUrl("");
    setTitle(null);
    setShowModal(false);
  };

  return (
    <>
      <div className="mt-8 px-2 pt-2 bg-[#0000cb]">
        <h1 className="p-2 mb-5 text-white text-center font-bold">
          OUR PRODUCTS
        </h1>

        <div className="bg-[#06b050] py-2 mb-2">
          <h1 className="text-red-700 ml-3 font-bold text-lg">
            <span className="font-bold text-2xl text-black mr-1">➟</span>
            HYDRAULIC FIXTURES AND MANDRELS
          </h1>
          <div className="ml-[70px] text-sm text-white text-md font-bold cursor-pointer">
            {HYDRAULIC_FIXTURES_AND_MANDRELS.map((item, index) => {
              return (
                <h1
                  key={item}
                  className="mb-2 cursor-pointer"
                  onClick={() => openModal(imageProducts[index], null)}
                >
                  <span className="text-red-700 mr-2">»</span>
                  {item}
                </h1>
              );
            })}
          </div>
        </div>
        <div className="bg-[#06b050] py-2 mb-2">
          <h1 className="text-red-700 ml-3 font-bold text-lg">
            <span className="text-2xl font-bold text-black mr-1">➟</span>JIG AND
            FIXTURES
          </h1>
          <div className="ml-[70px] text-white text-sm font-bold">
            {JIG_AND_FIXTURES.map((item) => {
              return (
                <h1 key={item} className="mb-2">
                  <span className="text-red-700 mr-2">»</span>
                  {item}
                </h1>
              );
            })}
          </div>
        </div>
        <div className="bg-[#06b050] py-2">
          <h1 className="text-red-700 ml-3 font-bold text-lg">
            <span className="text-2xl font-bold text-black mr-1">➟ </span>
            GAUGES
          </h1>
          <div className="ml-[70px] text-white text-sm font-bold">
            {GAUGES.map((item, index) => {
              return (
                <h1
                  key={item}
                  className="cursor-pointer"
                  onClick={() => openModal(imagesJig[index], item)}
                >
                  <span className="text-red-700 mr-2">»</span>
                  {item}
                </h1>
              );
            })}
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          src={imageUrl}
          alt="Your Image"
          onClose={closeModal}
          title={title}
        />
      )}
    </>
  );
}

export default OurProduct;
