import { useEffect } from "react";
import JigProduct from "./JigProduct";
import ProductList from "./ProductList";
import { useLocation } from "react-router-dom";

const Product = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollTo");
    const specificDiv = document.getElementById(scrollTo);
    if (specificDiv) {
      specificDiv.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  return (
    <div id="productItems">
      <div id="1" className="mb-5">
        <ProductList />
      </div>
      <div id="2" className="-ms-2">
        <JigProduct />
      </div>
    </div>
  );
};

export default Product;
