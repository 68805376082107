import React from "react";
import { CUSTOMERS1, CUSTOMERS2 } from "../constant/productType";
import title_image from "../images/title-icon.png";

function Home() {
  return (
    <div className="mx-20 mb-10 -mt-4">
      <h1 className="px-2 bg-[#0000cb] text-white text-3xl text-center h-24 flex items-center justify-center">
        WELCOME TO SUPERTECH ENGINEERING INDUSTRIES
      </h1>
      <div className="mt-3">
        <div className="relative">
          <img className="w-[200px] h-[50px]" src={title_image} alt="" />
          <h1 className="absolute ml-10 mt-[14px] top-0 text-white font-semibold text-lg">
            OUR VISION
          </h1>
          <p className="ml-20 text-xs mt-5">
            “SUPERTECH” Company vision” is to be recognized as the best
            Transmission solutions for D&D (Design & Development) Service
            Company globally & to help individual and business realize their
            full potential creating the most compelling service company in a
            decade to be world's leading solution provider in Design &
            development. We would like to see our organisation to be a global
            competitor in coming years. Initially we are in transmission
            solutions but we would like to spread our wings in coming years so
            as to become a potential Design Service Provider in auto components
            industry.
          </p>
        </div>
      </div>
      <div className="mt-3">
        <div className="relative">
          <img className="w-[200px] h-[50px]" src={title_image} alt="" />
          <h1 className="absolute ml-7 mt-[14px] top-0 text-white font-semibold text-lg">
            OUR MISSION
          </h1>
          <p className="ml-20 text-xs mt-5">
            “SUPERTECH” Company strives to develop & design transmission
            solutions by using state-of-the-art technology, innovation,
            leadership & partnership. Our motto is to rise un-noticed.
          </p>
          <br />
          <p className="ml-20 text-xs">
            “SUPERTECH” Company aims to continuously improve Transmission
            industry efficiency in india and developing markets, thereby
            reducing costs for Design & Development - leading to higher
            enablement of specialization in auto components, manufacturing and
            Design services, thereby increasing the nation's economic activity
            and productivity also creating employment for professionals. We work
            with professionalism, passion and the greatest respect for all
            individuals.
          </p>
        </div>
      </div>

      <div className="ml-20 mt-16 px-2 pb-2 bg-[#0000cb] w-[350px]">
        <div>
          <h1 className="text-sm font-bold text-white ">
            Our Valuable & Trageted Customers
          </h1>
        </div>
        <div className="bg-white flex flex-row justify-between text-xs">
          <div>
            {CUSTOMERS1.map((item) => {
              return (
                <h1 key={item} className="p-1">
                  <span className="text-red-700">»</span>
                  {item}
                </h1>
              );
            })}
          </div>
          <div>
            {CUSTOMERS2.map((item) => {
              return (
                <h1 key={item} className="p-1 pr-5">
                  <span className="text-red-700">»</span>
                  {item}
                </h1>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
