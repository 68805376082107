import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import OurProduct from "./OurProduct";
import name from "../images/name.png";

function LeftSide() {
  return (
    <>
      <div className="bg-[#0000cb] pt-5 pb-2 pl-3">
        <Link href="/" className="flex flex-row overflow-auto">
          <img className="h-10" src={logo} alt="Flowbite Logo" />
          <img className="pr-8" src={name} alt="name" />
        </Link>
      </div>

      <div className="bg-[#0000cb] mt-[1px] text-white flex flex-col justify-center text-center items-center text-sm">
        <p>Website : www.supertechengineering.co.in</p>
        <p>E-mail : sales@supertechengineering.co.in</p>
        <p className="mt-2 ms-1">
          Address :- Sector A, 1/1 New Bajrangpura Sanwar Road Industrial
          Area,Indore(M.P.)
        </p>
        <div className="flex space-x-10 text-[#ffff00] font-bold my-2">
          <p>Mob.: 9425981831</p>
          <p>GSTIN NO. 23GLBPK9747N1ZG</p>
        </div>
      </div>

      <div>
        <OurProduct />
      </div>
    </>
  );
}

export default LeftSide;
