import React, { useState, useEffect } from "react";
import { RxDotFilled } from "react-icons/rx";
import image1 from "../images/slide1.png";
import image2 from "../images/silde2.png";
import image3 from "../images/slide3.png";
import image4 from "../images/slide4.png";
import image5 from "../images/slide5.png";
import image6 from "../images/slide6_1.png";
import image61 from "../images/slide6_2.png";
import image7 from "../images/slide7_1.png";
import image71 from "../images/slide7_2.png";
import image8 from "../images/slide8.png";
import image9 from "../images/slide9.png";
import image10 from "../images/slide10.png";

const SlideShow = () => {
  const [imagePaths] = useState([
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image61,
    image7,
    image71,
    image8,
    image9,
    image10,
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const isLastSlide = currentIndex === imagePaths.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    }, 4000); // Change image every 2 seconds (2000 milliseconds)
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [currentIndex, imagePaths.length]);

  return (
    <div className="h-[350px]">
      <div className="flex justify-center">
        <img
          src={imagePaths[currentIndex]}
          className={`rounded-2xl duration-500 ${
            currentIndex === 0 ? "h-[350px] " : "h-[280px]"
          }`}
          alt=""
        />
      </div>

      <div className="flex justify-center cursor-pointer text-2xl">
        {imagePaths.map((slide, slideIndex) => (
          <RxDotFilled key={slideIndex} onClick={() => goToSlide(slideIndex)} />
        ))}
      </div>
    </div>
  );
};

export default SlideShow;
