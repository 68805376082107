import React from "react";

function ProductCart({ image }) {
  return (
    <div className="border border-black my-6 px-4">
      <img className="h-[450px]" src={image} alt="productimage" />
    </div>
  );
}

export default ProductCart;
