import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Header from "./component/header";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Product from "./component/Product";
import Contact from "./component/Contact";
import App from "./component/App";
import Footer from "./component/Footer";
import Download from "./component/Download";
import About from "./component/About";
import ManualHyd from "./pages/ManualHyd";
import AutoClampingHyd from "./pages/AutoClampingHyd";
import ClampingHyd from "./pages/ClampingHyd";
import MechenicalHyd from "./pages/MechenicalHyd";
import RotationalHyd from "./pages/RotationalHyd";
import LeftSide from "./component/LeftSide";

const AppLayout = () => {
  return (
    <React.Fragment>
      <div className="flex">
        <div className="w-[30.4%] h-screen overflow-y-auto">
          <LeftSide />
        </div>
        <div className="w-[69%] h-screen overflow-y-auto">
          <Header />
          <Outlet />
        </div>
        <div className="w-[.6%] bg-[#0000cb] h-screen overflow-y-auto"></div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: "/", element: <App /> },
      {
        path: "/product",
        element: <Product />,
      },
      { path: "/product/0", element: <ManualHyd /> },
      { path: "/product/1", element: <RotationalHyd /> },
      { path: "/product/2", element: <ClampingHyd /> },
      { path: "/product/3", element: <MechenicalHyd /> },
      { path: "/product/4", element: <AutoClampingHyd /> },
      { path: "/contact", element: <Contact /> },
      { path: "/download", element: <Download /> },
      { path: "/about", element: <About /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);
