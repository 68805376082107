import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div className="my-10 mx-32">
      <div className="flex justify-center">
        <h1 className="font-bold inline-flex rounded-lg text-black text-3xl text-center bg-blue-400 p-2">
          Contact Us
        </h1>
      </div>
      <div className="text-gray-700 m-7">
        <h1 className="font-bold text-black text-2xl">
          SUPERTECH ENGINEERING INDUSTRIES
        </h1>
        <h2 className="text-blod text-xl">
          <span className="font-bold">Address :-</span> Sector A, 1/1 New
          Bajrangpura Sanwar Road Industrial Area, Indore (M.P)
        </h2>
        <h2 className="text-xl">
          <span className="font-bold">PinCode:-</span> 455001 (India).
        </h2>
        <h2 className="text-xl">
          <span className="text-xl font-bold">Telephone : </span> +91-7272 -
          350784
        </h2>
        <h2 className="text-xl">
          <span className="text-xl font-bold">Mobile No. : </span>+91 9425981831
        </h2>
        <h2 className="text-xl">
          <span className="text-xl font-bold">E-mail : </span>
          sales@supertechengineering.co.in
        </h2>
        <h2 className="text-xl flex flex-row flex-1">
          <Link to="/">
            <span className="text-xl font-bold">Website : </span>
            www.supertechengineering.co.in
          </Link>
        </h2>
      </div>
      <div className="my-8 place-items-center">
        <iframe
          className="w-[800px] h-[500px] p-8"
          title="contact-location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.183500075641!2d75.8431751!3d22.7585704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396303df9ff1af25%3A0x6402afcd970f0789!2sNew%20bajrang%20pura!5e0!3m2!1sen!2sin!4v1714737980848!5m2!1sen!2sin"
          allowfullscreen=""
          loading="eager"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
