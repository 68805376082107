import React from "react";

function JigProductCard({ data1, data2, data3 }) {
  return (
    <div className="border-2 border-black">
      <div className="flex flex-row justify-between mx-3 space-x-2">
        <div className="my-2">
          <h1 className="font-bold text-[#0000cb] text-center text-base">
            <span className="text-red-700 text-2xl font-bold ">»</span>
            {data1.title}
          </h1>
          <div className="border-2 border-black text-center mb-2">
            <img className="w-80 h-80" src={data1.image} alt="image12"></img>
            <h1>{data1.name}</h1>
          </div>
        </div>
        <div className="my-2">
          <h1 className="font-bold text-base text-[#0000cb] text-center">
            <span className="text-red-700 text-2xl font-bold ">»</span>
            {data2.title}
          </h1>
          <div className="border-2 border-black text-center mb-2 ">
            <img className="w-80 h-80" src={data2.image} alt="image12"></img>
            <h1>{data2.name}</h1>
          </div>
        </div>
        <div className="my-2">
          <h1 className="font-bold text-base text-[#0000cb] text-center">
            <span className="text-red-700 text-2xl font-bold ">»</span>
            {data3.title}
          </h1>
          <div className="border-2 border-black text-center mb-2">
            <img className="w-80 h-80" src={data3.image} alt="image12"></img>
            <h1>{data3.name}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JigProductCard;
