import React from "react";
import { useNavigate } from "react-router-dom";

const ManualHyd = () => {
  const nagivate = useNavigate();
  window.scrollTo(0, 0);
  const image1Url =
    "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/18.jpeg?alt=media&token=e2952a30-3689-416e-9a3b-f62c93a9892f";

  const image2Url =
    "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/17.png?alt=media&token=2d14945a-a162-4195-ab4e-02a8daf94163";

  const image3Url =
    "https://firebasestorage.googleapis.com/v0/b/super-engineering-tech.appspot.com/o/12.jpeg?alt=media&token=1af42e32-b878-48be-8b39-d2086f7655cd";
  const closePageHandler = () => {
    nagivate("/product");
  };

  return (
    <div className="rounded-lg mt-10 bg-slate-400 m-12 mx-5">
      <div
        className="flex justify-end text-3xl mx-8 cursor-pointer"
        onClick={closePageHandler}
      >
        <p className="mt-5">X</p>
      </div>

      <p className="flex justify-center text-3xl font-bold mb-4">
        Auto Clamping Fixture Developed For Hard Turning Machine
      </p>

      <div className="container p-6">
        <div className="mx-12 flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between">
          <img
            className="rounded-lg w-[250px] h-[450px]"
            src={image1Url}
            alt=""
          />
          <img
            className="rounded-lg w-[250px] h-[450px] ml-5"
            src={image2Url}
            alt=""
          />
          <div className="w-2/3 h-[450px]">
            <h2 className="text-2xl font-bold mb-4">Description:-</h2>
            <p className="ml-12 text-lg">
              The Auto Clamping Fixture designed for Hard Turning Machines is a
              precision tooling solution that streamlines the workholding
              process in machining operations. This innovative fixture employs
              automated clamping mechanisms, eliminating the need for manual
              adjustments and reducing setup time. Engineered specifically for
              hard turning applications, it ensures secure and stable workpiece
              fixation, enhancing machining accuracy and surface finish. The
              fixture's intelligent design incorporates advanced sensors and
              control systems to optimize clamping force, adapting to different
              workpiece geometries. This cutting-edge technology not only
              enhances operational efficiency but also contributes to the
              overall precision and reliability of hard turning processes.
            </p>
          </div>
        </div>

        <div className="mx-12 mt-8 flex flex-col lg:flex-row items-center justify-center lg:justify-between">
          <div className="w-2/3 text-lg">
            <h2 className="text-2xl font-bold">Benefits:-</h2>
            <ol className="list-decimal pl-5">
              <li className="mb-2">
                Gear Grinding, Thread Grinding and OD Grinding Operation can be
                done in high accuracy.
              </li>
              <li className="mb-2">
                Easy to check for PCD, OD Runout, and Lead Profile.
              </li>
              <li className="mb-2">Quality improve.</li>
              <li className="mb-2">Operator fatigue reduce.</li>
              <li className="mb-2">Cost Saving due to In-house Design and Mfg.</li>
            </ol>
          </div>

          <img
            className="w-[500px] h-[350px] rounded-lg"
            src={image3Url}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ManualHyd;
