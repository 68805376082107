import facebook from "../images/facebook-xxl.png";
import twitter from "../images/twitter-x-xxl.png";
import linkedin from "../images/linkedin-xxl.png";

const Footer = () => {
  return (
    <div className="flex flex-col items-center h-44 shadow bg-[#0000cb]">
      <div className="flex space-x-1 mt-6">
        <img
          className="h-10 w-10 bg-gray-700 p-3 rounded-lg"
          src={facebook}
          alt="facebook_icon"
        />
        <img
          className="h-10 w-10  bg-gray-700 p-3 rounded-lg"
          src={twitter}
          alt="twitter_icon"
        />
        <img
          className="h-10 w-10  bg-gray-700 p-3 rounded-lg"
          src={linkedin}
          alt="linkedin_icon"
        />
      </div>
      <div className="text-md text-white mt-12">
        <span>
          Copyright© 2018 • SuperTech Engineering Industries • All rights
          reserved
        </span>
      </div>

      {/* <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          Designed by: Vikash Kumar
        </span> */}
    </div>
  );
};

export default Footer;
