import React from "react";

const About = () => {
  return (
    <div className="ml-20 mt-10">
      <p className="text-black font-bold text-2xl">Company Profile</p>
      <p className="mt-10 mr-5 text-lg">
        “SUPERTECH” Company had taken roots in the year 2019 to provide quality
        clamping solutions to the industry. Presently the company is engaged in
        designing and manufacturing of custom built hydraulic expanding
        mandrels, Jig and Fixtures and Gauges for Gear manufacturing and other
        precision applications for automobile industry in India and Globally.
        The Company was founded by Mr. R.K Singh. He had his previous experience
        in the field of manufacturing and assembly of fixtures and special
        purpose machines. In the initial years the company supported industries
        in and around Indore and Pithampur for various clamping solutions mainly
        with collet type and other mechanical fixtures. Right from the start,
        the vision of the director of company is very clear to provide their
        customers world class quality and development of import substitutes.
      </p>
    </div>
  );
};

export default About;
