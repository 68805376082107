import html2canvas from "html2canvas";
import React, { useEffect } from "react";
import Product from "./Product";

const Download = () => {
  const downloadPDF = () => {
    const content = document.getElementById("productItems");

    html2canvas(content).then((canvas) => {
      const imageData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imageData;
      link.download = "SuperTechEngineering.png";
      link.click();
    });
  };

  useEffect(() => {
    downloadPDF();
  });

  return <Product />;
};

export default Download;
