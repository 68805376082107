import productImage1 from "../images/product1.png";
import productImage2 from "../images/product2.png";
import productImage3 from "../images/product3.png";
import productImage4 from "../images/product4.png";
import productImage5 from "../images/product5.png";
import productImage6 from "../images/product6.png";
import productImage7 from "../images/product7.png";
import productImage8 from "../images/product8.png";
import ProductCart from "./ProductCart";

const ProductList = () => {
  return (
    <div id="1" className="mt-5 mx-5 border border-black">
      <div>
        <h1 className="font-bold text-lg text-blue-700 ml-14 text-center">
          <span className="text-red-700 text-2xl font-bold mr-1">»</span>
          HYDRAULIC FIXTURES/MANDREL- PRINCIPLE OF WORKING
        </h1>
        <p className="font-bold text-sm mx-8">
          Hydraulic expanding Fixtures function by expanding or compressing
          under hydraulic pressure. The hydraulic system is integral power
          actuated. Extreme accuracy in component location is assured as the
          sleeves expand or contract under equalised hydraulic pressure
          uniformly from their geometric axis within 0.003 mm or less if
          required. Diagram below shows function of a hydraulic Fixture.
        </p>
      </div>

      <div className="flex flex-row mx-4 -mt-4 justify-between space-x-4">
        <div className="">
          <ProductCart image={productImage1} />
          <ProductCart image={productImage3} />
          <ProductCart image={productImage5} />
          <ProductCart image={productImage7} />
        </div>
        <div className="">
          <ProductCart image={productImage2} />
          <ProductCart image={productImage4} />
          <ProductCart image={productImage6} />
          <ProductCart image={productImage8} />
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default ProductList;
